@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  font-family: Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif-apple-system, BlinkMacSystemFont;
}

.box {
  width: 100%;
  max-width: 640px;
  margin: auto;
}

a {
  color: #f15421;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.heading a:hover {
  text-decoration: none;
}

.heading {
  text-align: center;
  background-color: #fff;
  padding: .5rem 1rem;
}

.heading span {
  vertical-align: middle;
}

.heading-title {
  margin-left: 1rem;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.content {
  text-align: center;
  padding: 3rem 1rem 4rem;
}

.footing {
  text-align: center;
}

button.cancel, button.retry {
  color: #fff;
  background-color: #f15421;
  border: 1px solid #ff7f4d;
  width: 48%;
  font-size: 14px;
  line-height: 42px;
  display: inline;
}

button.cancel:hover, button.retry:hover {
  color: #fff;
  cursor: pointer;
  background: #ff7f4d;
  border-color: #ff7f4d;
}

button.cancel {
  color: #333;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-right: 2%;
}

button.cancel:hover {
  color: #333;
  background: #ccc;
  border-color: #bbb;
}

button:disabled {
  color: #999;
  background: #eee;
  border-color: #eee;
}

@media only screen and (width <= 640px) {
  button.cancel, button.retry {
    width: 100%;
    margin-top: 1rem;
  }

  button.cancel {
    margin-right: 0%;
  }
}

.try-again {
  text-align: center;
  opacity: .5;
  font-size: .8rem;
}

.try-again:hover {
  opacity: 1;
}

/*# sourceMappingURL=index.85e11752.css.map */
