@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif-apple-system, BlinkMacSystemFont;
}

.box {
    width: 100%;
    max-width: 640px;
    margin: auto;
}

a {
    text-decoration: none;
    color: rgb(241, 84, 33);
}

a:hover {
    text-decoration: underline;
}

.heading a:hover {
    text-decoration: none;
}

.heading {
    background-color: #fff;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: center;
}

.heading span {
    vertical-align: middle;
}

.heading-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-left: 1rem;
}

.content {
    padding: 3rem 1rem 4rem 1rem;
    text-align: center;
}

.footing {
    text-align: center;
}

button.cancel,
button.retry {
    width: 48%;
    display: inline;
    line-height: 42px;
    font-size: 14px;
    background-color: rgb(241, 84, 33);
    border: 1px solid rgb(255, 127, 77);
    color: #fff;
}

button.cancel:hover,
button.retry:hover {
    background: #ff7f4d;
    border-color: #ff7f4d;
    color: #fff;
    cursor: pointer;
}

button.cancel {
    background-color: #eee;
    border: 1px solid #ccc;
    color: #333;
    margin-right: 2%;
}

button.cancel:hover {
    background: #ccc;
    border-color: #bbb;
    color: #333;
}

button:disabled {
    background: #eee;
    border-color: #eee;
    color: #999;
}

@media only screen and (max-width: 640px) {

    button.cancel,
    button.retry {
        margin-top: 1rem;
        width: 100%;
    }

    button.cancel {
        margin-right: 0%;
    }
}

.try-again {
    text-align: center;
    font-size: 0.8rem;
    opacity: 0.5;
}

.try-again:hover {
    opacity: 1;
}